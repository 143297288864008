<template>
  <v-container fluid class="px-6">

    <v-card>

      <v-card-title class="px-6 card-header white--text">
          <v-icon class="mr-3 white--text">mdi-email</v-icon> <h3>New Request List</h3>
      </v-card-title>

      <v-card-title class="pr-6">
        <v-spacer></v-spacer>

        <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-3 csv"
              >
                  <v-icon class="white--text">mdi-file-delimited</v-icon>
              </v-btn>
          </template>
          <span>Export to CSV</span>
        </v-tooltip> -->

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-3 excel"
              @click="exportExcel()"
              >
                  <v-icon class="white--text">mdi-microsoft-excel</v-icon>
              </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
        
        <v-text-field
            v-model="searchDt"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
            class="shrink"
        ></v-text-field>
      </v-card-title>
      
      <v-data-table
      :headers="theadDtApim"
      :items="tbodyDtApim"
      :search="searchDt"
      :loading="loadingDt"
      :items-per-page="5"
      class="elevation-1 px-6 headerDtSarawak pt-6"
      >
          <template v-slot:item.status="{ item }">
            <v-chip
            :color="item.status == 'APPROVE'? 'success' : 'error'"
            dark
            >
              {{item.status}}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
              <template>
                  <v-icon
                  class="mr-2 primary--text"
                  @click="formRequest(item)"
                  >
                    mdi-text-box
                  </v-icon>
              </template>
          </template>
        
      </v-data-table>

    </v-card>


    <v-dialog
    v-model="dialogFormApplication"
    persistent
    scrollable
    max-width="900px"
    >
      <v-card>

        <v-card-title>
          <span class="text-h5 black--text">Information</span>
          <v-spacer></v-spacer>
          <v-icon
          class="black--text"
          style="cursor:pointer;"
          @click="dialogFormApplication = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container ref="infoDetailsAPIRequestList" style="height: 790px;">

            <v-card-title>
              <span class="text-h6 cust-subtitle">Applicant Information</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="8">

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Full Name
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.fullname}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Ministry / Agency / NGO / IPT
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.agencyrequest}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Phone Number
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.phone}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Email
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.email}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Designation / Profession
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.position}}
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
            </v-card-text>

            <v-card-title>
              <span class="text-h6 cust-subtitle">About Data Sharing</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="8">

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Purpose of Use Web API
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.purpose}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Supporting Document
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <span class="font-weight-bold primary--text">:</span>
                        <v-icon 
                        class="primary--text"
                        style="cursor:pointer;"
                        @click="openDocument(tbodyInfoDetails.requestdoc)"
                        >
                          mdi-file-document
                        </v-icon>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
            </v-card-text>

            <v-card-title>
              <span class="text-h6 cust-subtitle">Water Quality Station</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col class="px-0">

                    <v-simple-table class="cust-simple-table-details-form">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th 
                            v-for="(item,i) in theadInfoDetails"
                            :key="i"
                            class="text-center"
                            style="text-wrap: nowrap !important;"
                            >
                              {{item}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.agencyrequest }}</td>
                            <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.api }}</td>
                            <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.location }}</td>
                            <td class="text-center" style="text-align:center;">
                              <!-- {{ tbodyInfoDetails.status }} -->
                              <v-chip
                              :style="tbodyInfoDetails.status == 'APPROVE'? 'background:#8ec33c;' : 'background:#ff3737;'"
                              dark
                              >
                                {{tbodyInfoDetails.status}}
                              </v-chip>
                            </td>
                            <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.dateexpire }}</td>
                            <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.remarks }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>
                </v-row>
            </v-card-text>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          class="mr-3"
          color="primary"
          @click="printBorang()"
          >
            Print
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>

import axios from "axios";
import Papa from "papaparse";
import html2pdf from "html2pdf.js";
import appMixins from '@/mixins/globalMixins'
import {bus} from '@/main'
// -- import something here --

export default {

  data: () => ({

    dataAllUsers: [],
    dataAllStations: [],

    loadingDt: false,
    searchDt: "",

    theadDtApim: [
      { text: 'Reference No', value: 'id', sortable: true, },
      { text: 'Email', value: 'email' },
      { text: 'Request Date & Time', value: 'requestdate' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Form', value: 'actions', align: 'center', sortable: false, },
    ],
    tbodyDtApim: [],

    theadInfoDetails: [
      "Agency",
      "Station ID",
      "Location",
      "Status",
      "Expired Date",
      "Remark",
    ],
    tbodyInfoDetails: null,

    // Details Form
    dialogFormApplication: false,
    formApproval: {
      id: "",
      status: "",
      dateexpire: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      remarks: "",
    },
    listApproval: ["Approve", "Reject"],
    modalMenuCalendar: false,




  }),

  mounted() {

    this.getDataAllUsers();

  },

  methods: {

    getDataAllUsers(){

      this.loadingDt = true;
      this.dataAllUsers = [];

      axios.get(appMixins.data().globalUrl+"mqm2/users/all", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;
        // console.log(data);

        this.dataAllUsers = data;

        this.getDataAllStations();

      })
      .catch((error) => {
        console.log(error.response);
        this.loadingDt = false;
      });

    },

    getDataAllStations(){

      this.dataAllUsers = [];

      axios.get(appMixins.data().globalUrl+"mqm2/miqims/stations", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;
        // console.log(data);

        this.dataAllStations = data;

        this.getAllDataAPIM();

      })
      .catch((error) => {
        console.log(error.response);
        this.loadingDt = false;
      });

    },

    getAllDataAPIM() {

      this.tbodyDtApim = [];

      const d = new Date();
      let currentYear = d.getFullYear();
      let prevYear = currentYear-1;

      axios.get(appMixins.data().globalUrl+"mqm2/apim/allapis?startreqdate="+prevYear+"-01-01&endreqdate="+currentYear+"-12-31", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;
        console.log(data);

        for(let i in data){
          data[i].requestdate = this.useConvertDisplayDatetime(data[i].requestdate);
          data[i].dateexpire = data[i].dateexpire == null? '' : this.useConvertDisplayDate(data[i].dateexpire.split("T")[0]);
        }

        let dataNonPending = [];
        for(let i in data){
          if(data[i].status != "PENDING"){
            dataNonPending.push(data[i])
          }
        }

        for(let i in dataNonPending){
          for(let x in this.dataAllUsers){
            if(this.dataAllUsers[x].email == dataNonPending[i].email){
              dataNonPending[i]["fullname"] = this.dataAllUsers[x].firstname + " " + this.dataAllUsers[x].lastname;
              dataNonPending[i]["phone"] = this.dataAllUsers[x].phoneNo;
            }
          }
        }

        for(let i in dataNonPending){
          for(let x in this.dataAllStations){
            if(this.dataAllStations[x].STATION_ID == dataNonPending[i]["api"]){
              dataNonPending[i]["location"] = this.dataAllStations[x].LOCATION;
            }
          }
        }

        this.tbodyDtApim = dataNonPending;
        this.loadingDt = false;

      })
      .catch((error) => {
        console.log(error.response);
        this.loadingDt = false;
      });

    },

    formRequest(item){

      this.tbodyInfoDetails = item;

      // console.log(this.tbodyInfoDetails["email"]);
      
      // for(let i in this.dataAllUsers){
      //   if(this.dataAllUsers[i].email == this.tbodyInfoDetails["email"]){
      //     this.tbodyInfoDetails["fullname"] = this.dataAllUsers[i].firstname + " " + this.dataAllUsers[i].lastname;
      //     this.tbodyInfoDetails["phone"] = this.dataAllUsers[i].phoneNo;
      //   }
      // }
      
      // for(let i in this.dataAllStations){
      //   if(this.dataAllStations[i].STATION_ID == this.tbodyInfoDetails["api"]){
      //     this.tbodyInfoDetails["location"] = this.dataAllStations[i].LOCATION;
      //   }
      // }

      // this.tbodyInfoDetails.dateexpire = this.useConvertDisplayDate(this.tbodyInfoDetails.dateexpire.split("T")[0]);

      this.formApproval.dateexpire = this.tbodyInfoDetails.dateexpire

      console.log(this.tbodyInfoDetails);

      

      this.dialogFormApplication = true;
      // console.log(this.formApplication, item);
      // this.detailForm = item;

    },

    openDocument(url){

      window.open(url, "_blank");

    },

    exportExcel(){

      let objKeys = Object.keys(this.tbodyDtApim[0])
      for(let i in this.theadDtApim){
        for(let x in objKeys){
          for(let y in this.tbodyDtApim){
            if(objKeys[x] == this.theadDtApim[i].value){
              this.tbodyDtApim[y][this.theadDtApim[i].text] = this.tbodyDtApim[y][objKeys[x]];
            }
          }
        }
      }

      let keyValueHeader = [];
      for(let i in this.theadDtApim){
        if(this.theadDtApim[i].text != "Form"){
          keyValueHeader.push(this.theadDtApim[i].text)
        }
      }

      let dataPrint = this.tbodyDtApim;

      let keys = keyValueHeader,
      resultsss = dataPrint.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
      let objExport = resultsss;

      console.log(objExport);

      let blob = new Blob([Papa.unparse(objExport)], { type: 'text/csv;charset=utf-8;' });

      let link = document.createElement("a");

      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'List of Request API.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    },

    printBorang(){

      console.log("Printt!");

      var opt = {
          margin:       0,
          filename:     'Maklumat Permohonan API.pdf',
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { scale: 2 },
          jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      html2pdf().set(opt).from(this.$refs.infoDetailsAPIRequestList).save();

    }
    
  },

  created() {
    bus.$on("showBadge", (countList) => {
      this.badgeCount = countList;
    });
  }

};
</script>

<style lang="scss">
@import '~scss/main';

.background{
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

// .v-list-item >  .v-list-item__icon > .v-badge{
//     height: 100px;
// }

// .v-badge__badge  {
  
//   height: 20px;
//   font-weight: bold;
//   font-size: small !important;
//   min-width: 20px;
//   color: white !important; 
//   margin-left: 8px; 
//   padding: .5px 3px .5px 3px; 
//   text-align: center; 
//   vertical-align: middle;
// }

.cust-subtitle{
  color: $primary;
  font-weight: bold;
}

.cust-subtitle-key{
  color: $primary;
  font-weight: 600;
}

.cust-subtitle-value{
  color: black;
}

// .cust-simple-table-details-form > .v-data-table__wrapper > table > thead > tr > th{
//   word-wrap: nowrap !important;
// }

.cust-simple-table-details-form > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 16px !important;
}


</style>


